@use './Home.scss';

.work-experience-container {
  @extend .BaseSection;
  display: grid;
  grid-template-columns: 25% 75%;
  gap: 4rem;
}

.work-experience-title {
  @extend .BaseSectionTitle;
  padding-top: 15px;
  text-align: right;
}

.work-experience-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.work-experience-item {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 10px;
}

.work-experience-icon {
  font-size: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
}

.work-experience-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}

.work-experience-date {
  font-size: 1.1em;
  color: rgb(49, 49, 49);
}

.work-experience-role {
  font-size: 1.4em;
  color: #000;
  font-family: 'Roboto', sans-serif;
}

.work-experience-logo {
  width: 1.1em;
  height: auto;
}

@media (max-width: 900px) {
  .work-experience-container {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .work-experience-title {
    text-align: center;
  }
}
