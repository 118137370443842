.Sidebar {
  height: 100vh;
  background-color: #2c3e50;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: sticky;
  top: 0;
  font-family: 'Roboto', sans-serif;
}

.Sidebar-logo {
  width: 76%;
  margin-top: 60px;
  margin-bottom: 80px;
  margin-left: 12%;
}

.Sidebar-link-wrapper {
    width: 100%;
}

.Sidebar-menu-link {
  color: white;
  text-decoration: none;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 12%;
  width: auto;
  display: block;
  text-align: left;
  transition: background-color 0.3s ease;
  font-size: 1.2em;
}

.Sidebar-menu-link:hover {
  background-color: #34495e;
}

.Sidebar-social {
  margin-top: auto;
  padding-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.Sidebar-social-icon {
  font-size: 1.8em;
  margin: 20px;
  color: white;
}

.Sidebar-social-icon:hover {
    color: #87cefa;
}

.Sidebar-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

@media (max-width: 768px) {
  .Sidebar {
    display: none;
  }

  .bm-menu-wrap {
    height: 100%!important;
  }

  .bm-item-list {
      display: flex;
      flex-direction: column;
      height: 100%;
  }

  .bm-item-list .Sidebar-social {
    margin-top: auto;
  }

  .bm-menu .bm-item.Sidebar-content {
      display: flex !important;
  }
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

.bm-burger-bars {
  background: #9aa09a;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: #ffffff;
}

.bm-menu {
  background: #2c3e50;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-morph-shape {
  fill: #2c3e50;
}

.bm-item {
  display: flex;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
