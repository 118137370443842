.BaseSection {
  padding: 3rem;
  border-bottom: 1px solid #ccc;
  background-color: #f9f9f9;
}

.BaseSectionTitle {
  font-size: 2.5em;
  font-family: 'Roboto', sans-serif;
}

.IntroSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  background-color: #34495e;
  color: white;
  position: relative;
  overflow: hidden;
}

.IntroSection h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.IntroTitle {
  font-size: 5rem;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  max-width: 80%;
  width: auto;
  overflow: hidden;
}

.IntroSection p {
  font-size: 1.5rem;
  margin: 5px 0;
}

.Education {
  position: absolute;
  bottom: 15%;
  left: 10%;
  padding: 10px;
  color: white;
  border-radius: 5px;
  text-align: left;
  font-style: italic;
  font-size: 1.75rem;
  font-weight: 400;
}

.TypingLine {
  overflow: hidden;
  white-space: pre-wrap;
}

.Caret {
  animation: blink-caret 0.75s step-end infinite;
}

@keyframes blink-caret {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .IntroTitle {
    font-size: 3rem;
    max-width: 95%;
  }

  .Education {
    font-size: 1.2rem;
  }
}
