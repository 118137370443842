@use './Home.scss';

.portfolio-section {
  @extend .BaseSection;
  text-align: center;
}

.portfolio-section-title {
  @extend .BaseSectionTitle;
  padding-bottom: 30px;
  text-align: center;
}

.portfolio-section-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 300px);
  grid-auto-rows: 300px;
  gap: 10px;
  justify-content: center;
  max-width: 1400px;
  margin: 0 auto;
  margin-bottom: 60px;
}

.portfolio-section-item {
  position: relative;
  overflow: hidden;
}

.portfolio-section-image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: all 0.3s ease;
}

.portfolio-section-image:hover .overlay {
  background: rgba(255, 255, 255, 0.8);
  opacity: 1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0);
  opacity: 0;
  display: flex;
  align-items: flex-start;
  transition: opacity 0.3s ease;
  padding-top: 60%;
  justify-content: flex-start;
}

.overlay-text {
  position: absolute; 
  left: 20px;
  font-size: 1.8rem;
  font-weight: bold;
  color: #000;
  transform: translateX(-100%);
  transition: transform 0.3s ease;
  text-align: left;
  padding-right: 20px;
}

.portfolio-section-image:hover .overlay-text {
  transform: translateX(0);
}

.portfolio-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
}

.portfolio-modal {
  position: relative;
  background-color: #fff;
  padding: 0;
  border-radius: 5px;
  max-width: 90%;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: 0 auto;
    object-fit: contain;
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
}
