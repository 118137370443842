@use './Home.scss';

.skills-section {
  @extend .BaseSection;
  display: grid;
  grid-template-columns: 25% 75%;
  gap: 4rem;
  font-family: 'Roboto', sans-serif;
}

.skills-section-title {
  @extend .BaseSectionTitle;
  text-align: right;
}

.skills-section-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-right: 4em
}

.skills-section-item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.skills-section-item-name {
  font-size: 1.3rem;
}

.skills-section-item-bar {
  position: relative;
  background-color: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  height: 10px;
}

.skills-section-item-fill {
  background: linear-gradient(to right, #00c6ff, #0072ff);
  height: 100%;
  width: 0%;
  border-radius: 10px 0 0 10px;
  transition: width 1.5s ease-out;
}

.skills-section-item-level {
  text-align: right;
  font-size: 0.9rem;
  color: #666;
}

@media (max-width: 900px) {
  .skills-section {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .skills-section-title {
    text-align: center;
  }
}
