.App {
  display: grid;
  grid-template-columns: minmax(150px, 300px) 1fr;
  min-height: 100vh;
}

@media (max-width: 768px) {
  .App {
    grid-template-columns: 1fr;
  }
}
