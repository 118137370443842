@use './Home.scss';

.contact-section {
  @extend .BaseSection;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif;
}

.contact-section-title {
  @extend .BaseSectionTitle;
  text-align: center;
}

.contact-section-list {
  list-style: none;
  padding: 0;
}

.contact-section-list-item {
  margin-bottom: 10px;
}

.contact-section-text {
  text-align: center;
  font-size: 1.2rem;
  margin-top: 20px;
}