@use './Home.scss';

.achievements-section {
  @extend .BaseSection;
  display: grid;
  grid-template-columns: 25% 75%;
  gap: 4rem;
  font-family: Arial, sans-serif;
}

.achievements-section-title {
  @extend .BaseSectionTitle;
  text-align: right;
}

.achievements-section-list {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.achievements-section-list-item {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  margin-bottom: 1rem;
  color: #4a4a4a;
}

.achievements-section-icon {
  font-size: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  color: #0072ff;
  margin-right: 0.5rem;
}

.achievements-section-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}

.achievements-section-text {
  font-size: 1.4em;
  color: #000;
  font-family: 'Roboto', sans-serif;
}

@media (max-width: 900px) {
  .achievements-section {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .achievements-section-title {
    text-align: center;
  }
}
